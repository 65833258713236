<template>
  <div class="modal-container">
    <div class="overlay"></div>
    <div class="alert-dialog shake-r">
      <div class="btn-close" @click="closed" style="top: 0px; z-index: 10">
        <i class="ri-close-fill"></i>
      </div>

      <div
        style="
          position: relative;
          width: 100%;
          height: 50px;
          border-bottom: 2px solid var(--canvas);
        "
      >
        <span class="alert-title">{{ title }}</span>
      </div>

      <div
        style="
          position: relative;
          width: 100%;
          height: 85px;
          border-bottom: 2px solid var(--canvas);
          display: flex;
          align-items: center;
        "
      >
        <span class="alert-message">{{ message }}</span>
      </div>

      <div class="alert-button">
        <button class="submit-btn" @click="callServices">
          <spinner v-if="loading"></spinner>
          <span v-if="!loading">Confirm</span>
        </button>

        <button class="btn-danger" @click="closed">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import axios from "axios";

export default {
  name: "AlertConfirm",
  props: ["title", "message", "methods", "url", "data", "header"],
  components: {
    Spinner,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    closed() {
      this.$emit("onClosed", false);
    },
    async callServices() {
      try {
        this.loading = true;

        console.log(this.data)
        const response = await axios(this.url, {
          method: this.methods,
          headers: this.header,
          data: this.data,
        });

        this.$emit("onResolve", response.data);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  position: relative;
  width: 90%;
  max-width: 800px;
  height: 90%;
  max-height: 850px;
  background: var(--white);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
}

.overlay{
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background: #0000008e;
}

.theme-scroll {
  --sb-track-color: #e5e5e5;
  --sb-thumb-color: #0da487;
  --sb-size: 9px;
  scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
}

.theme-scroll::-webkit-scrollbar {
  width: var(--sb-size);
}

.theme-scroll::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 10px;
}

.theme-scroll::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 10px;
}

.modal-title {
  font-size: 20pt;
  margin-top: 20px;
  font-weight: bold;
  color: var(--dark);
}

.modal-content {
  width: 90%;
}

.alert-dialog {
  position: relative;
  width: 90%;
  max-width: 400px;
  height: 200px;
  background: var(--white);
  border-radius: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.alert-title {
  position: relative;
  top: 15px;
  left: 20px;
  font-size: 16pt;
  font-family: "Lexend", sans-serif;
  color: var(--light);
}

.alert-message {
  position: relative;
  width: 90%;
  margin: 0 auto;
  font-size: 11pt;
  color: var(--dark);
  font-family: 'Outfit', sans-serif;
}

.alert-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 10px;
}

.shake-r {
  animation: shake-r .6s ease;
}

@keyframes shake-r {
  0% {transform: translateX(0);}
  10%{transform: translateX(10px);}
  25%{transform: translateX(-10px);}
  50% {transform: translateX(10px);}
  75% {transform: translateX(-10px);}
  100% {transform: translateX(0);}
}

.btn-close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 26pt;
  cursor: pointer;
  transition: all 0.5s ease;
}

.btn-close:hover {
  font-size: 29pt;
}

.btn-danger{
  height: 44px;
  padding: 0 15px;
  border-radius: 3px;
  background: #e74c3c;
  font-size: 13pt;
  background: -webkit-linear-gradient(bottom, #e74c3c, #db240f);
  background: linear-gradient(to bottom, #e74c3c, #db240f);        
  font-family: 'Outfit', sans-serif;
  border: none;
  cursor: pointer;
  color: var(--white);
}

.btn-danger:hover{
  background: #941304;
}
</style>
