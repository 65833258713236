<template>
  <div>
    <img
      src="/blur-bg.jpg"
      v-if="wScroll < 44.8"
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      "
    />
    <img
      src="/contact-bg.jpg"
      v-if="wScroll > 40"
      style="
        position: fixed;
        botom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      "
    />

    <div class="bcontainer" v-if="wScroll < 41">
      <nav
        class="navbar"
        style="background: transparent; justify-content: end; box-shadow: none"
      >
        <router-link
          :to="{ name: 'login' }"
          class="primary-btn"
          style="margin-right: 3svw"
        >
          Login
        </router-link>
      </nav>
    </div>

    <div class="content">
      <div class="header-content">
        <div class="payment-text">
          <span class="first-text" :style="{ 'font-size': firstText + 'pt' }"
            >A better way to
          </span>
          <span class="second-text" :style="{ 'font-size': secondText + 'px' }"
            >Make Payment</span
          >
          <p :style="{ 'font-size': descText + 'px' }">
            Send money with a better exchange <br />
            rate and avoid excessive bank fees.
          </p>
        </div>
        <img
          src="/hand-pay.png"
          v-if="wSize > 768"
          style="
            position: absolute;
            bottom: 0;
            right: 10px;
            width: 30%;
            filter: contrast(1.2) brightness(0.8);
          "
        />
      </div>

      <div class="body-content">
        <span class="header-text" :style="{ 'font-size': title + 'px' }"
          >The simple way to make payment with us</span
        >
        <span class="subheader-text" :style="{ 'font-size': descTitle + 'px' }"
          >Experience the ease of making payments through our user-friendly
          payment gateway. With a simple and intuitive interface, you can
          effortlessly navigate the process, ensuring a quick transaction. Enjoy
          the convenience of multiple payment options and a seamless checkout
          experience, making it easier than ever to complete your transactions
          securely and efficiently.</span
        >

        <div class="steps-wrapper">
          <div class="step">
            <span class="step-left">1</span>
            <div class="step-right">
              <span class="step-title"> Receive Payment Link </span>
              <p>
                Our customer service will forward a secure payment link to you
                via WhatsApp.
              </p>
            </div>
          </div>

          <div class="step">
            <span class="step-left">2</span>
            <div class="step-right">
              <span class="step-title"> Choose Payment Method </span>
              <p>
                Select your preferred payment method, consider the convenience,
                security, and flexibility it offers. choose the option that
                aligns best with your preferences.
              </p>
            </div>
          </div>

          <div class="step">
            <span class="step-left">3</span>
            <div class="step-right">
              <span class="step-title"> Confirm Pay </span>
              <p>
                Completing your transaction, please verify the payment by
                confirming the details provided. Ensure accuracy in the amount,
                recipient, and payment method of your payment.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="contact-content">
        <div class="contact-card">
          <div class="contact-left" :style="{ width: cwidth + '%' }">
            <div class="landing-form">
              <span style="font-size: 30pt; font-weight: bold; color: #474747"
                >Send Us Message</span
              >
              <div class="flex">
                <label style="width: 45%">
                  <input
                    class="input"
                    type="text"
                    placeholder=""
                    required=""
                    id="first-name"
                  />
                  <span>first name</span>
                </label>

                <label style="width: 45%">
                  <input
                    class="input"
                    type="text"
                    placeholder=""
                    required=""
                    id="last-name"
                  />
                  <span>last name</span>
                </label>
              </div>

              <label>
                <input
                  class="input"
                  type="email"
                  placeholder=""
                  required=""
                  id="email"
                />
                <span>email</span>
              </label>

              <label>
                <input
                  class="input"
                  placeholder=""
                  type="tel"
                  required=""
                  id="telp"
                />
                <span>contact number</span>
              </label>

              <label>
                <textarea
                  class="input01"
                  placeholder=""
                  rows="3"
                  required=""
                  id="message"
                ></textarea>
                <span>message</span>
              </label>

              <button class="fancy" @click="sendMessages">
                <div v-if="is_proccess" class="spinner center">
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                </div>

                <span v-if="!is_proccess">
                  <span class="top-key"></span>
                  <span class="text">Submit</span>
                  <span class="bottom-key-1"></span>
                  <span class="bottom-key-2"></span>
                </span>
              </button>
            </div>
          </div>

          <div class="contact-right" v-if="wSize > 768">
            <l-map
              ref="map"
              v-model:zoom="zoom"
              :center="[currentlat, currentlng]"
            >
              <l-tile-layer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                layer-type="base"
                name="OpenStreetMap"
              ></l-tile-layer>
              <l-marker
                :lat-lng="[currentlat, currentlng]"
                :radius="14"
                :color="'red'"
                :stroke="false"
                :fill="true"
                :fillColor="'#a5082a'"
                :fillOpacity="0.4"
              />
            </l-map>
          </div>
        </div>
      </div>

      <div class="about-content">
        <div class="about-left">
          <span class="company-name">Contact Lens Store</span>
          <p class="address">
            Jl Cucur Jaya A2/6, Bintaro Jaya IV, Desa/Kelurahan Pondok Karya
            Kec. Pondok Aren <br />Tangerang Selatan - Indonesia
          </p>
        </div>
        <div class="about-right">
          <div class="about-section">
            <span class="about-title">Customer Care</span>
            <a href="mailto:contaclens.care@gmail.com" class="about-desc"
              >contaclens.care@gmail.com</a
            >
            <a href="telp:08158816257" class="about-desc">08158816257</a>
          </div>
        </div>
      </div>

      <div class="footer-content">
        <span class="footer-text" :style="{ 'font-size': footerText + 'px' }">
          Copyright © 2023 All rights reserved Contac Lens.
        </span>
      </div>
    </div>
  </div>

  <notification
    v-if="showAlert"
    :message="message"
    :success="success"
  ></notification>
</template>

<script>
import Notification from "@/components/Notification.vue";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";

export default {
  name: "LandingView",
  components: {
    Notification,
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      firstText: 0,
      secondText: 0,
      descText: 0,
      wSize: 0,
      wScroll: 0,
      title: 0,
      descTitle: 0,
      footerText: 0,
      cwidth: 0,
      currentlat: "-6.2617818",
      currentlng: "106.7433444",
      zoom: 17,
      showAlert: false,
      success: false,
      message: null,
      is_proccess: false,
    };
  },
  created() {
    this.setFontSize();
    window.addEventListener("scroll", () => {
      this.wScroll = this.getScrollPercent();
      console.log(this.wScroll);
    });
  },
  methods: {
    getScrollPercent() {
      let h = document.documentElement,
        b = document.body,
        st = "scrollTop",
        sh = "scrollHeight";

      const result =
        ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
      return Math.round(result * 100) / 100;
    },
    async sendMessages() {
      try {
        if (this.is_proccess) return;
        this.is_proccess = true;

        const data = {
          firstname: document.getElementById("first-name").value,
          lastname: document.getElementById("last-name").value,
          email: document.getElementById("email").value,
          telp: document.getElementById("telp").value,
          message: document.getElementById("message").value,
        };

        await axios.post("/payment/send-message", data);

        this.is_proccess = false;

        this.success = true;
        this.message = "Message sent!";
        this.showAlert = true;

        setTimeout(() => {
          this.showAlert = false;
          this.success = false;
          this.message = null;
          window.location.reload();
        }, 1300);
      } catch (error) {
        this.is_proccess = false;
        console.log(error);
      }
    },
    setFontSize() {
      this.wSize = window.screen.width;
      this.wScroll = window.scrollY;

      this.cwidth = 50;
      this.firstText = this.wSize / 42;
      this.secondText = this.wSize / 25;
      this.descText = this.wSize / 70;
      this.title = this.wSize / 50;
      this.descTitle = this.wSize / 80;
      this.footerText = this.wSize / 90;

      if (this.wSize < 768) {
        this.cwidth = 86;
        this.firstText = this.wSize / 16;
        this.secondText = this.wSize / 10;
        this.descText = this.wSize / 30;
        this.title = this.wSize / 16;
        this.descTitle = this.wSize / 25;
        this.footerText = this.wSize / 35;
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.cdnfonts.com/css/fredoka");
@import url("https://fonts.cdnfonts.com/css/fjalla-one-2");
@import url("https://fonts.cdnfonts.com/css/aux");

.company-name {
  font-family: "Aux", sans-serif;
  font-size: 18pt;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
}

.header-content {
  position: relative;
  width: 100%;
  height: 100svh;
  background: #000000a6;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.payment-text {
  width: 80%;
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-left: 10%;
}

.payment-text p {
  font-family: "Fredoka", sans-serif;
}

.payment-text .first-text {
  font-family: "Fredoka", sans-serif;
  font-weight: bold;
}

.payment-text .second-text {
  font-family: "Fjalla One", sans-serif;
  font-weight: bold;
  margin-top: 10px;
}

.body-content {
  width: 100%;
  min-height: 90svh;
  background: #fff;
  opacity: 1;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Fredoka", sans-serif;
  gap: 5px;
}

.body-content .header-text {
  width: 90%;
  margin: 0 auto;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-top: 10px;
}

.body-content .subheader-text {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  font-size: 14pt;
}

.body-content .steps-wrapper {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 30px;
}

.body-content .steps-wrapper .step {
  width: 100%;
  max-width: 400px;
  height: auto;
  min-height: 170px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.body-content .steps-wrapper .step .step-left {
  font-family: Arial, Helvetica, sans-serif;
  width: 25%;
  height: 100%;
  font-size: 80pt;
  font-weight: bold;
  display: flex;
  color: #d2d2d2;
}

.body-content .steps-wrapper .step .step-right {
  width: 75%;
  height: 100%;
}

.body-content .steps-wrapper .step .step-right .step-title {
  font-size: 18pt;
  font-weight: bold;
}

.body-content .steps-wrapper .step .step-right p {
  font-size: 15pt;
  text-align: justify;
}

@media (max-width: 768px) {
  .body-content .steps-wrapper .step .step-right p {
    font-size: 12pt;
    text-align: justify;
  }

  .body-content .steps-wrapper .step .step-right .step-title {
    font-size: 15pt;
    font-weight: bold;
  }
}

.contact-content {
  position: relative;
  width: 100%;
  min-height: 700px;
  background: #7878789b;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  font-family: "Fredoka", sans-serif;
}

.contact-content .contact-card {
  width: 90%;
  height: 600px;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-left {
  position: relative;
  height: 100%;
}

.contact-right {
  position: relative;
  width: 45%;
  height: 100%;
}

.about-content {
  width: 100%;
  min-height: 40svh;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;
  font-family: "Fredoka", sans-serif;
}

.about-left {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 5%;
}

.about-left .about-logo {
  width: 300px;
  height: 100px;
  object-fit: cover;
}

.about-right {
  position: relative;
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.about-right .about-section {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.about-right .about-section .about-title {
  font-size: 15pt;
  font-weight: bold;
  margin-bottom: 30px;
}

.about-right .about-section .about-desc {
  font-size: 14pt;
  margin-top: 10px;
  text-decoration: none;
  color: #2b2b2b;
}

.about-right .about-section .about-desc:hover {
  color: #a10a0a;
}

@media (max-width: 768px) {
  .about-left {
    width: 90%;
    text-align: center;
  }

  .about-left .about-logo {
    margin: 0 auto;
  }

  .about-right {
    width: 90%;
  }

  .about-right .about-section {
    width: 90%;
  }

  .about-right .about-section .about-title {
    font-size: 15pt;
    margin-bottom: 10px;
  }

  .about-right .about-section .about-desc {
    font-size: 14pt;
    margin-top: 10px;
    text-decoration: none;
  }
}

.footer-content {
  width: 100%;
  height: 5svh;
  background: #646464;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #fff;
}

.footer-content .footer-text {
  height: 100%;
  font-family: "Fredoka", sans-serif;
  text-align: center;
  margin-top: 10px;
}

/* Landing Form */
.landing-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 100%;
  padding: 15px;
  position: relative;
  margin-top: -50px;
}

.message {
  color: #355891;
  font-size: 14px;
}

.flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 6px;
}

.landing-form label {
  position: relative;
  width: 100%;
}

.landing-form label .input {
  width: 100%;
  padding: 10px 10px 20px 10px;
  outline: 0;
  border: 1px solid #355891;
  border-radius: 5px;
}

.landing-form label .input + span {
  position: absolute;
  left: 10px;
  top: 15px;
  color: #355891;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.landing-form label .input:placeholder-shown + span {
  top: 15px;
  font-size: 0.9em;
}

.landing-form label .input:focus + span,
.landing-form label .input:valid + span {
  top: 30px;
  font-size: 0.7em;
  font-weight: 600;
}

.landing-form label .input:valid + span {
  color: green;
}

.input01 {
  width: 100%;
  padding: 10px 10px 20px 10px;
  outline: 0;
  border: 1px solid #355891;
  border-radius: 5px;
}

.landing-form label .input01 + span {
  position: absolute;
  left: 10px;
  top: 50px;
  color: #355891;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.landing-form label .input01:placeholder-shown + span {
  top: 40px;
  font-size: 0.9em;
}

.landing-form label .input01:focus + span,
.landing-form label .input01:valid + span {
  top: 50px;
  font-size: 0.7em;
  font-weight: 600;
}

.landing-form label .input01:valid + span {
  color: green;
}

.fancy {
  position: relative;
  background-color: white;
  border: 2px solid #355891;
  border-radius: 0px;
  box-sizing: border-box;
  color: #355891;
  cursor: pointer;
  display: inline-block;
  font-weight: 390;
  letter-spacing: 2px;
  margin: 0;
  outline: none;
  overflow: visible;
  width: 103%;
  height: 50px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  user-select: none;
  font-size: 13px;
  margin: 0 auto;
}

.fancy::before {
  content: " ";
  width: 1.7rem;
  height: 2px;
  background: #355891;
  top: 50%;
  left: 1.5em;
  position: absolute;
  transform: translateY(-50%);
  transform: translateX(230%);
  transform-origin: center;
  transition: background 0.3s linear, width 0.3s linear;
}

.fancy .text {
  font-size: 1.125em;
  line-height: 1.33333em;
  padding-left: 2em;
  display: block;
  text-align: left;
  transition: all 0.3s ease-in-out;
  text-transform: lowercase;
  text-decoration: none;
  color: #355891;
  transform: translateX(30%);
}

.fancy .top-key {
  height: 2px;
  width: 1.5625rem;
  top: -2px;
  left: 0.625rem;
  position: absolute;
  background: #355891;
  transition: width 0.5s ease-out, left 0.3s ease-out;
}

.fancy .bottom-key-1 {
  height: 2px;
  width: 1.5625rem;
  right: 1.875rem;
  bottom: -2px;
  position: absolute;
  background: #355891;
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.fancy .bottom-key-2 {
  height: 2px;
  width: 0.625rem;
  right: 0.625rem;
  bottom: -2px;
  position: absolute;
  background: #355891;
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.fancy:hover {
  color: #355891;
  background: #5f82a9;
}

.fancy:hover::before {
  width: 2rem;
  background: #355891;
}

.fancy:hover .text {
  color: white;
  padding-left: 5em;
}

.fancy:hover .top-key {
  left: -2px;
  width: 0px;
}

.fancy:hover .bottom-key-1,
.fancy:hover .bottom-key-2 {
  right: 0;
  width: 0;
}

.primary-btn {
  width: 100px;
  height: 44px;
  background: #087ba5;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Fredoka", sans-serif;
  font-weight: bold;
}

.primary-btn:hover {
  background: #064f69;
}

.show-btn {
  width: 28%;
  height: 70px;
  border: 2px solid #740820;
  background: transparent;
  color: #740820;
  font-size: 21pt;
  font-family: "Fredoka", sans-serif;
  font-weight: bolder;
  transition: 0.5s ease;
  cursor: pointer;
}

.show-btn:hover {
  background: #740820;
  color: #fff;
}
</style>
