<template>
  <div class="body-container">
    <div class="payment-dialog">
      <div v-if="!isBussy" id="snap-container" 
      style="width: 100%; height: 100%"></div>

      <div v-if="isBussy">
        <div class="main-error-page">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="auto"
            height="290"
            viewBox="0 0 470 290"
          >
            <defs>
              <path
                id="prefix__a"
                d="M221.677 31.582c-55.54 19.895-112.156 36.51-167.848 55.993C33.02 94.854 8.92 106.19 5.062 129.52c-2.513 15.198 5.634 31.108 17.898 38.96 5.99 3.835 13.34 6.531 16.472 13.261 4.87 10.459-3.88 22.449-13.113 28.531-9.236 6.083-20.5 10.905-24.704 21.695-1.121 2.876-1.61 5.944-1.615 9.05v.082c.006 4.547 1.05 9.169 2.674 13.386 10.06 26.125 39.391 37.567 65.477 36.17 26.086-1.398 50.827-12.414 76.417-18.085 43.668-9.678 88.389-3.63 133.098 2.418 45.256 6.122 90.501 12.244 134.63 2.054 13.65-3.153 27.4-8.086 37.944-17.999 11.883-11.174 18.63-28.03 19.648-45.048.069-1.15.106-2.31.112-3.477v-.557c-.067-15.128-5.551-31.08-17.856-37.794-7.833-4.274-17.195-4.1-25.7-1.775-5.43 1.485-10.766 3.811-16.368 3.85-5.601.04-11.763-3-13.386-8.812-1.707-6.11 2.182-12.416 6.642-16.586 9.073-8.477 21.203-12.714 29.44-22.137 7.927-9.07 11.265-22.586 8.575-34.734-2.692-12.149-11.326-22.552-22.189-26.73-14.224-5.471-29.105-.465-44.051 4.542-14.139 4.736-28.34 9.474-42.1 5.336-2.1-.63-4.312-1.606-5.418-3.643-1.08-1.989-.835-4.511-.214-6.719 3.468-12.355 16.938-20.22 17.528-33.12.322-7.01-3.504-13.572-8.325-18.26C327.188 4.247 316.599.782 305.483.782c-28.103 0-59.605 22.132-83.806 30.8z"
              />
              <path
                id="prefix__c"
                d="M39.89.625c-2.162 6.638-7.77 12.79-13.571 16.612-9.236 6.082-20.5 10.904-24.704 21.694C.494 41.807.005 44.875 0 47.98v.082c.006 4.547 1.05 9.17 2.674 13.386 10.06 26.126 39.391 37.568 65.477 36.17 26.086-1.397 50.827-12.414 76.417-18.085 43.668-9.678 88.389-3.629 133.098 2.419 45.256 6.122 90.501 12.244 134.63 2.054 13.65-3.154 27.4-8.086 37.944-18 11.883-11.174 18.63-28.03 19.648-45.048.069-1.15.106-2.31.112-3.477v-.557c-.025-5.484-.761-11.079-2.277-16.3H39.89z"
              />
              <path
                id="prefix__e"
                d="M.99 2.778v5.157c0 1.457 1.467 2.638 3.277 2.638s3.278-1.181 3.278-2.638V2.778c0-1.457-1.467-2.637-3.278-2.637C2.457.141.99 1.321.99 2.778z"
              />
              <path
                id="prefix__g"
                d="M.577 2.778v5.157c0 1.457 1.468 2.638 3.279 2.638 1.81 0 3.278-1.181 3.278-2.638V2.778c0-1.457-1.468-2.637-3.278-2.637S.576 1.321.576 2.778z"
              />
              <path
                id="prefix__i"
                d="M.39 2.037c0 1.08.88 1.956 1.966 1.956 1.087 0 1.968-.877 1.968-1.956 0-1.08-.88-1.956-1.968-1.956C1.27.081.39.957.39 2.037z"
              />
              <path
                id="prefix__k"
                d="M.39 2.466c0 1.08.88 1.956 1.966 1.956 1.087 0 1.968-.875 1.968-1.956 0-1.079-.88-1.954-1.968-1.954C1.27.512.39 1.387.39 2.466z"
              />
              <path
                id="prefix__m"
                d="M1.963.022C.893.022.026.898.026 1.977c0 1.08.867 1.956 1.937 1.956H16.45c1.068 0 1.935-.876 1.935-1.956 0-1.079-.867-1.955-1.935-1.955H1.963z"
              />
              <path
                id="prefix__o"
                d="M5.214 5.34c-.85 3.38 1.072 5.145 5.14 11.15L.464 55.893l44.419 11.492 9.888-39.401c6.384-3.286 8.919-3.909 9.77-7.295.793-3.159-1.093-6.383-4.206-7.189L12.294 1.071c-.48-.125-.962-.185-1.437-.185-2.598 0-4.972 1.783-5.643 4.453z"
              />
              <path
                id="prefix__q"
                d="M5 6.465c-1.055 4.052 1.213 6.332 4.666 11.2l-9.2 35.38 2.075.52 9.442-36.306c-3.886-5.53-5.717-7.165-4.909-10.276.758-2.91 3.791-4.675 6.762-3.933l45.868 11.453c2.971.742 4.772 3.714 4.015 6.624-.81 3.118-3.222 3.687-9.328 6.72L44.95 64.154l2.076.518 9.2-35.38c5.404-2.654 8.514-3.59 9.57-7.646 1.047-4.032-1.447-8.15-5.563-9.177L14.365 1.016c-.635-.159-1.273-.234-1.9-.234-3.44 0-6.58 2.274-7.465 5.683z"
              />
              <path
                id="prefix__s"
                d="M5.916.737C2.89.737.428 3.265.428 6.37c0 3.326 2.16 4.498 7.25 9.07v32.236h43.2V15.44c5.077-4.56 7.25-5.74 7.25-9.07 0-3.105-2.462-5.633-5.488-5.633H5.916z"
              />
              <path
                id="prefix__u"
                d="M7.721.63C3.638.63.314 4.073.314 8.3c0 4.249 2.667 5.935 7.065 9.878v30.697h2.058V17.21C4.476 12.72 2.375 11.562 2.375 8.3c0-3.05 2.398-5.535 5.346-5.535h45.511c2.949 0 5.347 2.484 5.347 5.534 0 3.269-2.108 4.422-7.062 8.911v31.665h2.06V18.178c4.389-3.939 7.061-5.626 7.061-9.879 0-4.227-3.32-7.668-7.406-7.668H7.722z"
              />
              <path
                id="prefix__w"
                d="M4.278.171C2.09.171.314 1.931.314 4.097v43c0 2.168 1.776 3.926 3.964 3.926H69.79c2.187 0 3.963-1.758 3.963-3.926v-43C73.753 1.93 71.977.17 69.79.17H4.278z"
              />
              <path
                id="prefix__y"
                d="M.102 2.593C-.017 3.898.95 5.053 2.26 5.177c1.308.122 2.469-.835 2.591-2.138C4.97 1.735 4.007.58 2.696.456 2.62.448 2.544.445 2.47.445 1.253.445.217 1.367.102 2.593z"
              />
              <path
                id="prefix__A"
                d="M.007 2.613c-.119 1.303.844 2.459 2.155 2.582 1.311.122 2.472-.834 2.593-2.138.12-1.304-.843-2.46-2.154-2.582-.077-.009-.153-.011-.227-.011-1.216 0-2.253.92-2.367 2.149z"
              />
              <path
                id="prefix__C"
                d="M.79 3.73c-.144.275.15.627.337.37C3.783.462 7.604.373 9.772 4.237c.244.43.852-.108.639-.557C9.353 1.496 7.533.36 5.676.36 3.852.36 1.994 1.456.79 3.73z"
              />
              <path
                id="prefix__E"
                d="M8.699 4.235L2.878 2.667c-.528-.144-1.074.182-1.219.725-.143.547.167 1.102.693 1.243l5.821 1.572-1.076 4.048-5.821-1.572c-.528-.141-1.073.183-1.217.729-.145.544.164 1.1.692 1.24l5.822 1.573-.9 3.377 7.313 1.973c4.037 1.088 8.21-1.407 9.32-5.573.753-2.832-.102-5.71-1.996-7.605-.893-.891-2.014-1.564-3.307-1.914L9.69.511 8.7 4.235z"
              />
              <path
                id="prefix__G"
                d="M1.203 6.693c-1.123 4.183 1.356 8.479 5.536 9.596l7.57 2.024 1.534-5.714 2.531-9.428-7.569-2.025C10.128.966 9.445.88 8.775.88c-3.462 0-6.632 2.31-7.572 5.814z"
              />
            </defs>
            <g fill="none" fill-rule="evenodd">
              <path
                fill="#FFF"
                d="M0 0H1366V800H0z"
                transform="translate(-448 -158)"
              />
              <g>
                <g
                  transform="translate(-448 -158) translate(448 157) translate(0 .61)"
                >
                  <mask id="prefix__b" fill="#fff">
                    <use xlink:href="#prefix__a" />
                  </mask>
                  <path
                    fill="#F6F6F7"
                    d="M-5.995 296.763L475.995 296.763 475.995 -5.213 -5.995 -5.213z"
                    mask="url(#prefix__b)"
                  />
                </g>
                <g
                  transform="translate(-448 -158) translate(448 157) translate(0 193.645)"
                >
                  <mask id="prefix__d" fill="#fff">
                    <use xlink:href="#prefix__c" />
                  </mask>
                  <path
                    fill="#EDEDF0"
                    d="M-20.194 115.101L487.354 115.101 487.354 36.101 -20.194 36.101z"
                    mask="url(#prefix__d)"
                  />
                </g>
                <path
                  class="fio-500"
                  stroke="#2D3F5B"
                  stroke-linecap="round"
                  stroke-width="4"
                  d="M42.92 106.015c1.69-.15 3.386-.39 5.08-.454 1.496-.056 3.045.109 4.354.832 2.559 1.415 3.61 4.459 6.173 5.892 3.477 1.945 7.237 2.323 11.14 2.395 4.592.085 9.185.091 13.777.069 9.192-.045 18.386-.2 27.579.004 1.455.032 2.97.052 4.292-.562 2.606-1.208 2.6-4.556-.086-5.63-1.088-.434-2.291-.441-3.463-.441l-32.929-.022c-2.27-.001-5.629.548-5.724 3.443-.215 6.553.078 15.199.713 21.722.17 1.76.492 3.722 1.92 4.769.896.657 2.057.81 3.166.904 6.696.573 13.43-.22 20.15-.185 6.721.034 13.7.992 19.326 4.657 10.875 7.087 11.581 26.256-1.336 31.914-6.591 2.887-14.242 2.35-21.267 2.257-7.79-.103-12.714.024-20.504-.132-1.647-.034-3.648-.288-4.304-1.797-.574-1.324.355-2.913 1.64-3.573 1.285-.663 2.802-.642 4.248-.603 32.437.865 62.03 4.399 94.49 2.362 15.62-.979 26.11-9.971 29.058-25.472 2.41-12.67-.035-28.363-10.01-37.409-1.853-1.68-3.952-3.08-6.24-4.098-9.326-4.147-21.037-3.107-28.714 3.924-3.952 3.619-6.283 8.75-7.291 14.006-1.948 10.149-2.077 21.397 1.549 31.197 4.503 12.17 15.295 17.937 27.852 19.106 22.936 2.138 46.167 2.247 68.908-1.419 6.817-1.1 14.77-3.416 19.496-8.795 11.362-12.935 9.584-40.905-1.435-53.582-9.245-10.635-26.641-9.576-36.17.345-4.034 4.198-6.308 9.85-7.244 15.591-2.07 12.71-.474 27.655 8.267 37.863 5.498 6.42 14.934 9.316 22.9 11.018 5.7 1.217 11.59 1.935 17.404 1.17 5.668-.749 11.264-3.322 17.003-1.433 3.896 1.282 7.194 5.378 6.09 9.328-1.146 4.102-6.015 5.773-10.238 6.335-17.139 2.28-34.126-2.651-51.125 1.61-3.002.753-5.983 1.835-8.431 3.728-2.449 1.894-4.32 4.697-4.543 7.785-.835 11.582 23.435 6.912 30.629 10.509"
                  transform="translate(-448 -158) translate(448 157)"
                />
                <g
                  transform="translate(-448 -158) translate(448 157) translate(389.862 231.813)"
                >
                  <mask id="prefix__n" fill="#fff">
                    <use xlink:href="#prefix__m" />
                  </mask>
                  <path
                    class="fio"
                    fill="#4B4B62"
                    d="M-5.969 9.929L24.38 9.929 24.38 -5.973 -5.969 -5.973z"
                    mask="url(#prefix__n)"
                  />
                </g>

                <path
                  class="fio"
                  stroke="#2D3F5B"
                  stroke-linecap="round"
                  stroke-width="4"
                  d="M332.999 253.51c-7.766-.244-14.938 4.491-22.688 4.744-2.34.077-4.8.01-6.847-1.128-2.662-1.483-3.966-4.531-3.511-7.501.564-3.683 3.808-5.906 6.02-8.612 3.643-4.453 2.47-9.05-2.76-11.169-5.66-2.293-11.803-3.685-17.833-4.585"
                  transform="translate(-448 -158) translate(448 157)"
                />

                <g class="full-torradeira">
                  <g
                    transform="translate(-448 -158) translate(448 157) translate(332.51 262.987)"
                  >
                    <mask id="prefix__f" fill="#fff">
                      <use xlink:href="#prefix__e" />
                    </mask>
                    <path
                      fill="#4B4B62"
                      d="M-5.006 16.566L13.541 16.566 13.541 -5.855 -5.006 -5.855z"
                      mask="url(#prefix__f)"
                    />
                  </g>
                  <g
                    transform="translate(-448 -158) translate(448 157) translate(384.066 262.987)"
                  >
                    <mask id="prefix__h" fill="#fff">
                      <use xlink:href="#prefix__g" />
                    </mask>
                    <path
                      fill="#4B4B62"
                      d="M-5.418 16.566L13.129 16.566 13.129 -5.855 -5.418 -5.855z"
                      mask="url(#prefix__h)"
                    />
                  </g>
                  <g
                    transform="translate(-448 -158) translate(448 157) translate(396.056 247.4)"
                  >
                    <mask id="prefix__j" fill="#fff">
                      <use xlink:href="#prefix__i" />
                    </mask>
                    <path
                      fill="#4B4B62"
                      d="M-5.605 9.989L10.318 9.989 10.318 -5.913 -5.605 -5.913z"
                      mask="url(#prefix__j)"
                    />
                  </g>
                  <g
                    transform="translate(-448 -158) translate(448 157) translate(396.056 254.793)"
                  >
                    <mask id="prefix__l" fill="#fff">
                      <use xlink:href="#prefix__k" />
                    </mask>
                    <path
                      fill="#4B4B62"
                      d="M-5.605 10.418L10.318 10.418 10.318 -5.483 -5.605 -5.483z"
                      mask="url(#prefix__l)"
                    />
                  </g>

                  <g class="pao-atras">
                    <g
                      transform="translate(-448 -158) translate(448 157) translate(340.903 169.665)"
                    >
                      <mask id="prefix__p" fill="#fff">
                        <use xlink:href="#prefix__o" />
                      </mask>
                      <path
                        fill="#FFDA7F"
                        d="M-5.53 73.379L70.718 73.379 70.718 -5.109 -5.53 -5.109z"
                        mask="url(#prefix__p)"
                      />
                    </g>
                    <g
                      transform="translate(-448 -158) translate(448 157) translate(340.903 168.466)"
                    >
                      <mask id="prefix__r" fill="#fff">
                        <use xlink:href="#prefix__q" />
                      </mask>
                      <path
                        fill="#E6A95F"
                        d="M-5.53 70.667L72.03 70.667 72.03 -5.213 -5.53 -5.213z"
                        mask="url(#prefix__r)"
                      />
                    </g>
                  </g>

                  <g class="pao-frente">
                    <g
                      transform="translate(-448 -158) translate(448 157) translate(326.515 182.854)"
                    >
                      <mask id="prefix__t" fill="#fff">
                        <use xlink:href="#prefix__s" />
                      </mask>
                      <path
                        fill="#FFDA7F"
                        d="M-5.568 53.67L64.123 53.67 64.123 -5.259 -5.568 -5.259z"
                        mask="url(#prefix__t)"
                      />
                    </g>
                    <g
                      transform="translate(-448 -158) translate(448 157) translate(325.316 181.655)"
                    >
                      <mask id="prefix__v" fill="#fff">
                        <use xlink:href="#prefix__u" />
                      </mask>
                      <path
                        fill="#E6A95F"
                        d="M-5.68 54.87L66.634 54.87 66.634 -5.363 -5.68 -5.363z"
                        mask="url(#prefix__v)"
                      />
                    </g>

                    <g
                      transform="translate(-448 -158) translate(448 157) translate(344.301 197.242)"
                    >
                      <mask id="prefix__z" fill="#fff">
                        <use xlink:href="#prefix__y" />
                      </mask>
                      <path
                        class="olho-esq"
                        fill="#633"
                        d="M-4.153 -6.995L12.298 -3.797 9.107 12.627 -7.346 9.429z"
                        mask="url(#prefix__z)"
                      />
                    </g>
                    <g
                      transform="translate(-448 -158) translate(448 157) translate(361.087 197.242)"
                    >
                      <mask id="prefix__B" fill="#fff">
                        <use xlink:href="#prefix__A" />
                      </mask>
                      <path
                        class="olho-dir"
                        fill="#633"
                        d="M-4.248 -6.976L12.203 -3.778 9.011 12.645 -7.441 9.448z"
                        mask="url(#prefix__B)"
                      />
                    </g>
                    <g
                      transform="translate(-448 -158) translate(448 157) translate(350.495 206.834)"
                    >
                      <mask id="prefix__D" fill="#fff">
                        <use xlink:href="#prefix__C" />
                      </mask>
                      <path
                        class="boca"
                        fill="#633"
                        d="M-3.14 -7.546L17.993 -3.438 14.8 12.985 -6.333 8.878z"
                        mask="url(#prefix__D)"
                      />
                    </g>
                  </g>
                  <g
                    class="torradeira"
                    transform="translate(-448 -158) translate(448 157) translate(325.316 218.624)"
                  >
                    <mask id="prefix__x" fill="#fff">
                      <use xlink:href="#prefix__w" />
                    </mask>
                    <path
                      fill="#C81414"
                      d="M-5.68 57.017L79.748 57.017 79.748 -5.823 -5.68 -5.823z"
                      mask="url(#prefix__x)"
                    />
                  </g>
                </g>
                <g
                  class="raios"
                  stroke="#fdba55"
                  stroke-linecap="round"
                  stroke-width="1.8"
                >
                  <path
                    d="M18.393 5.769L21.35 2.82M9.304 4.452L7.306.783M14.502 3.516L14.981.207M3.272 28.271L.229 31.133M12.317 29.853L14.208 33.577M7.095 30.637L6.52 33.93"
                    transform="translate(-448 -158) translate(448 157) translate(260.571 203.237)"
                  />
                </g>
                <g
                  transform="translate(-448 -158) translate(448 157) translate(272.561 215.226)"
                >
                  <mask id="prefix__F" fill="#fff">
                    <use xlink:href="#prefix__E" />
                  </mask>
                  <path
                    class="tomada"
                    fill="#2D3F5B"
                    d="M10.383 -14.007L34.351 9.962 12.875 31.438 -11.095 7.469z"
                    mask="url(#prefix__F)"
                  />
                </g>
                <g
                  transform="translate(-448 -158) translate(448 157) translate(246.184 208.033)"
                >
                  <mask id="prefix__H" fill="#fff">
                    <use xlink:href="#prefix__G" />
                  </mask>
                  <path
                    class="tomada"
                    fill="#2D3F5B"
                    d="M10.807 -12.875L32.391 8.709 10.793 30.309 -10.793 8.724z"
                    mask="url(#prefix__H)"
                  />
                </g>
              </g>
            </g>
          </svg>

          <h1 class="error-title">Woops! <br />Something went wrong :(</h1>
          <h2 class="error-subtitle">
            Have you tried turning it off and on again?
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "HomeView",
  data() {
    return {
      isBussy: false,
      clientKey: null,
    };
  },
  created() {
    this.generatePayment();
  },
  methods: {
    async generatePayment() {
      try {
        const body = {
          id: this.$route.params.orderId
        }

        await axios
          .post("/payment/generate-payment", body)
          .then((response) => {
            if (response.data.data === false) {
              this.isBussy = true;
            } else {
              this.isBussy = false;
              const url = "https://app.midtrans.com/snap/snap.js";
              // const url = "https://app.sandbox.midtrans.com/snap/snap.js";

              const script = document.createElement("script");
              script.src = url;
              script.setAttribute("type", "text/javascript");
              script.setAttribute("data-client-key", response.data.data.clientkey);
              script.async = true;
              document.body.appendChild(script);

              setTimeout(() => {
                const token = response.data.data.token;
                window.snap.embed(token, {
                  embedId: "snap-container",
                  onSuccess: () => {
                    window.location.href = `/sucess`
                  },
                  onPending: async() => {
                    try {
                      const { data } = await axios.get('/payment/generate-orderid/' + this.$route.params.orderId)
                      window.location.href = `/payment/${data.data}`
                    } catch(error){
                      console.log(error)
                    }
                  },
                  onError: () => {
                    window.location.href = `/failed`
                  },
                  onClose: async() => {                    
                    try {
                      const { data } = await axios.get('/payment/generate-orderid/' + this.$route.params.orderId)
                      window.location.href = `/payment/${data.data}`
                    } catch(error){
                      console.log(error)
                    }
                  }
                });
              }, 1000);
            }
          });
      } catch (error) {
        this.isBussy = true;
        // if(error.response.status == 406) {
        //   this.isFull = error.response.data.message
        // } else {
        //   this.$router.push('/')
        // }
      }
    },
  },
};
</script>

<style>
.body-container {
  position: absolute;
  top: 0;
  left: 0;
  background: #f0f0f0;
  background-image: url("../assets/doodle-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100svw;
  height: 100svh;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body-container .payment-dialog {
  position: relative;
  width: 90%;
  max-width: 800px;
  height: 90svh;
  max-height: 900px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 10px 10px 15px -5px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center
}

/* Server Bussy */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.main-error-page {
  min-height: 600px;
  margin: 0px auto;
  width: auto;
  max-width: 560px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.error-title {
  max-width: 529px; 
  font-family: Roboto;
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4b4b62;
  margin-bottom: 16px;
}

.error-subtitle {
  max-width: 568px; 
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #4b4b62;
  margin-bottom: 24px;
}
svg {
  margin-bottom: 16px;
}

.pao-atras {
  animation: leftright 1s alternate infinite;
  transform-origin: center;
}
.pao-frente {
    animation: leftright 1s 0.3s alternate infinite;
  transform-origin: center;
}

.olho-esq {
  
    animation: sad 2s alternate infinite;
  transform-origin: center;
}

.olho-dir {
  
    animation: sad 2s alternate infinite;
  transform-origin: center;
}
.boca {
    animation: sad 2s alternate infinite;
  transform-origin: center;
}
.raios {
  -webkit-animation: flicker-4 4s linear infinite both;
  animation: flicker-4 4s linear infinite both;
}
.tomada {
  -webkit-animation: vibrate-1 3s linear infinite both;
  animation: vibrate-1 3s linear infinite both;
}
.fio-500 {
  -webkit-animation: vibrate-1 3s linear infinite both;
  animation: vibrate-1 3s linear infinite both;
}
.fio{ 
  -webkit-animation: vibrate-1 3s linear infinite both;
  animation: vibrate-1 3s linear infinite both;
}

@keyframes scales {
  from {
    transform: scale(0.98);
  }
  to {
    transform: scale(1);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 14:58:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flicker-4
 * ----------------------------------------
 */
@-webkit-keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  32.8% {
    opacity: 0;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.7% {
    opacity: 0;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  37.6% {
    opacity: 0;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  68.4% {
    opacity: 0;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  96.7% {
    opacity: 0;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  99.6% {
    opacity: 0;
  }
  99.62% {
    opacity: 1;
  }
}
@keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  32.8% {
    opacity: 0;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.7% {
    opacity: 0;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  37.6% {
    opacity: 0;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  68.4% {
    opacity: 0;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  96.7% {
    opacity: 0;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  99.6% {
    opacity: 0;
  }
  99.62% {
    opacity: 1;
  }
}


/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 15:17:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}



/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 15:42:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes wobble-ver-right {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(6deg);
            transform: translateY(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(-6deg);
            transform: translateY(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(3.6deg);
            transform: translateY(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(-2.4deg);
            transform: translateY(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(1.2deg);
            transform: translateY(-6px) rotate(1.2deg);
  }
}
 

@keyframes sad {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(10deg)  rotateY(5deg);
  }
}

@keyframes leftright{
  
  0%{
    transform: rotateZ( 0deg)
  }
   100%{
    transform: rotateZ( -15deg)
  }
}
</style>
