<template>
  <div class="container">
    <div class="search-container">
      <div class="left-panel">
        <input
          type="text"
          class="search-input"
          v-model="searchItem"
          @keyup.enter="searching"
        />
        <button class="search-btn" @click="searching">Search</button>
      </div>

      <button class="refresh-btn" @click="refresh">
        <i class="ri-restart-fill" style="font-size: 20pt"></i>
      </button>
    </div>

    <loader v-if="isLoading"></loader>

    <table class="table-responsive" aria-describedby="Payment Table">
      <thead>
        <th style="width: 10%">CustKD</th>
        <th style="width: 30%">Nama Customer</th>
        <th style="width: 15%">Nomor Kontrak</th>
        <th style="width: 15%">Nilai</th>
        <th style="width: 10%">Order-ID</th>
        <th style="width: 20%">Action</th>
      </thead>

      <tbody v-if="!isloading">
        <tr v-for="data in payments[selectedPage]" :key="data.order_id">
          <td>{{ data.cust_kd }}</td>
          <td>{{ data.cust_name }}</td>
          <td>{{ data.items[0].kontrak }}</td>
          <td>Rp. {{ formatPrice(data.nilai) }}</td>
          <td>{{ data.order_id }}</td>
          <td
            style="
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
            "
          >
            <button class="preview-btn" @click="createPayment(data)">
              <div
                :id="'loadPreview' + data.order_id"
                style="display: none"
                class="dot-spinner"
              >
                <div class="dot-spinner__dot"></div>
                <div class="dot-spinner__dot"></div>
                <div class="dot-spinner__dot"></div>
                <div class="dot-spinner__dot"></div>
                <div class="dot-spinner__dot"></div>
                <div class="dot-spinner__dot"></div>
                <div class="dot-spinner__dot"></div>
                <div class="dot-spinner__dot"></div>
              </div>

              <i :id="'preview' + data.order_id" class="ri-news-line"></i>
            </button>

            <button class="send-btn" @click="sendPayment(data)">
              <div
                :id="'loadSend' + data.order_id"
                style="display: none"
                class="dot-spinner"
              >
                <div class="dot-spinner__dot"></div>
                <div class="dot-spinner__dot"></div>
                <div class="dot-spinner__dot"></div>
                <div class="dot-spinner__dot"></div>
                <div class="dot-spinner__dot"></div>
                <div class="dot-spinner__dot"></div>
                <div class="dot-spinner__dot"></div>
                <div class="dot-spinner__dot"></div>
              </div>

              <span :id="'send' + data.order_id">
                <i class="ri-send-plane-fill" style="font-size: 15pt"></i>
                Send
              </span>
            </button>

            <div v-if="data.sending > 0">
              <i class="ri-check-double-line" 
                style="
                  font-size: 18pt;
                  color: #1594ba;">
              </i>
              <span style="color: #898989">
                {{ data.sending }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="page-wrapper" v-if="totalPage.length > 0">
      <div style="width: 50%">
        <span style="font-size: 10pt">
          Showing {{ pagelength }} entries.
        </span>
      </div>
      <div
        style="
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: end;
        "
      >
        <button
          class="page-prev"
          @click="prevPagination"
          :class="{ 'paginate-active': start >= 5 }"
        >
          Previous
        </button>
        <div
          class="page"
          v-for="pg in totalPage.slice(start, end)"
          :key="pg"
          :class="{
            'page-active': selectedPage === pg,
            'page-unactive': selectedPage !== pg,
          }"
          @click="selectedPage = pg"
        >
          {{ pg + 1 }}
        </div>
        <button
          :class="{ 'paginate-active': totalPage.length > end }"
          class="page-next"
          @click="nextPagination"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <div style="width:100%;height: 100px;">
  </div>

  <notification 
      v-if="showNotification"
      :message="message"
      :success="succes">
  </notification>
</template>

<script>
import Loader from '@/components/ContentLoader.vue';
import Notification from '@/components/Notification.vue';
import axios from "axios";

export default {
  name: "DashboardViewVue",
  components: {
    Loader,
    Notification,
  },
  data() {
    return {
      trx: [],
      searchItem: null,
      payment: [],
      isPreview: false,
      isPayment: false,
      pagelength: 0,
      payments: [],
      totalPage: [],
      perpage: 10,
      selectedPage: 0,
      isLoading: false,
      message: null,
      success: false,
      showNotification: false,
    };
  },
  mounted() {
    this.getInvoice();
  },
  methods: {
    searching() {
      this.trx = [];
      this.payments = [];
      this.totalPage = [];
      const searchTerm = "*" + this.searchItem + "*";
      const wildcardRegex = new RegExp(
        "^" + searchTerm.replace(/\*/g, ".*") + "$",
        "i"
      );
      this.trx = this.payment.filter((obj) =>
        Object.values(obj).some(
          (value) => typeof value === "string" && wildcardRegex.test(value)
        )
      );

      this.pagelength = this.trx.length;
      for (let i = 0; i < this.trx.length; i += parseInt(this.perpage)) {
        this.payments.push(this.trx.slice(i, i + parseInt(this.perpage)));
      }

      for (let i = 0; i < this.payments.length; i++) {
        this.totalPage.push(i);
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    generateUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    async createPayment(payment) {
      try {
        if (this.isPreview) return;
        if(payment.gateway === "VA" && payment.va === ""){
          this.message = "VA Number is null"
          this.showNotification = true

          setTimeout(() => {
            this.showNotification = false
            this.message = null
          }, 1300)
          return;
        }

        this.isPreview = true;
        document.getElementById(
          "loadPreview" + payment.order_id
        ).style.display = "inline";
        document.getElementById("preview" + payment.order_id).style.display =
          "none";

        const idpay = this.generateUUID();
        const body = {
          id: idpay,
          custId: payment.cust_kd,
          name: payment.cust_name,
          email: payment.cust_email,
          total: payment.nilai,
          serverkey: payment.server_key,
          clientkey: payment.client_key,
          notelp: payment.cust_phone,
          orderid: payment.order_id,
          items: payment.items,
          expiredDate: payment.expired_date,
          gateway: payment.gateway,
          va: payment.va,
          action: "Generate"
        };
        
        console.log(body)
        const { data } = await axios.post("/payment/create-payment", body, {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });

        if(payment.gateway === 'MDT'){
          window.open(
            `https://contaclens.biz.id/payment/${data.data}`,
            "_blank"
          );
        } else if(payment.gateway === "XNT") {
          window.open(data.data, "_blank");
        } else if(payment.gateway === "VA"){
          window.open(`https://contaclens.biz.id/payment-va/${data.data}`, "_blank");
        }

        this.isPreview = false;
        document.getElementById(
          "loadPreview" + payment.order_id
        ).style.display = "none";
        document.getElementById("preview" + payment.order_id).style.display =
          "inline";

        window.location.reload();
      } catch (error) {
        console.log(error);
        this.isPreview = false;
        document.getElementById(
          "loadPreview" + payment.order_id
        ).style.display = "none";
        document.getElementById(
          "preview" + payment.order_id
        ).style.display = "inline";
        alert(error);
      }
    },
    async sendPayment(payment) {
      try {
        if (this.isPayment) return;
        this.isPayment = true;
        document.getElementById("loadSend" + payment.order_id).style.display =
          "inline";
        document.getElementById("send" + payment.order_id).style.display =
          "none";

        const idpay = this.generateUUID();
        const body = {
          id: idpay,
          custId: payment.cust_kd,
          name: payment.cust_name,
          email: payment.cust_email,
          total: payment.nilai,
          serverkey: payment.server_key,
          clientkey: payment.client_key,
          notelp: payment.cust_phone,
          orderid: payment.order_id,
          items: payment.items,
          expiredDate: payment.expired_date,
          va: payment.va,
          action: "Sending"
        };

        const { data } = await axios.post("/payment/create-payment", body, {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });

        if(payment.gateway === 'MDT'){
          window.open(
            `https://wa.me/${payment.cust_phone}/?text=https://contaclens.biz.id/payment/${data.data}`,
            "blank"
          );
        } else if(payment.gateway === "XNT") {
          window.open(
            `https://wa.me/${payment.cust_phone}/?text=${data.data}`,
            "blank"
          );
        } else if(payment.gateway === "VA"){
          window.open(
            `https://wa.me/${payment.cust_phone}/?text=https://contaclens.biz.id/payment-va/${data.data}`,
            "blank"
          );
        }

        this.isPayment = false;
        document.getElementById("loadSend" + payment.order_id).style.display =
          "none";
        document.getElementById("send" + payment.order_id).style.display =
          "inline";

        window.location.reload();
      } catch (error) {
        console.log(error);
        this.isPayment = false;
        document.getElementById("loadSend" + payment.order_id).style.display =
          "none";
        document.getElementById("send" + payment.order_id).style.display =
          "inline";
        alert(error);
      }
    },
    refresh() {
      window.location.reload();
    },
    async getInvoice() {
      try {
        if(this.isLoading){
          return;
        }

        this.isLoading = true;
        const { data } = await axios.get("/payment/invoice", {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });

        this.payment = data.data;
        this.pagelength = this.payment.length;
        for (let i = 0; i < this.payment.length; i += parseInt(this.perpage)) {
          this.payments.push(this.payment.slice(i, i + parseInt(this.perpage)));
        }

        for (let i = 0; i < this.payments.length; i++) {
          this.totalPage.push(i);
        }

        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    prevPagination(){
        if(this.start <= 0) return;
        this.start -= 5
        this.end -= 5
    },
    nextPagination(){
        if(this.end > this.total_page.length) {
            this.start = this.total_page.length - 5
            this.end = this.total_page.length
            return;
        }

        this.start += 5
        this.end += 5
    },
  },
};
</script>

<style>
body{background: #dedede;}

.container {
  position: relative;
  top: 10svh;
  margin: 0 auto;
  width: 90svw;
  min-height: 86svh;
  background: #fff;
  border-radius: 10px;
  box-shadow: 17px 17px 11px -11px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding: 15px 0;
}

.search-container {
  position: relative;
  width: 96%;
  margin-top: 20px;
  margin-left: 2%;
  display: flex;
  justify-content: space-between;
}

.search-container .left-panel {
  width: 50%;
}

.search-container .search-input {
  width: 60%;
  max-width: 250px;
  height: 40px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid gray;
  padding-left: 10px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
textarea:focus {
  outline: 2px solid #740820;
  border: none;
}

.search-container .search-btn {
  width: 60px;
  height: 44px;
  background: #a5082a;
  color: #fff;
  border: none;
  cursor: pointer;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.search-container .search-btn:hover {
  background: #5c0619;
}

.search-container .refresh-btn {
  width: 60px;
  height: 44px;
  background: #087ba5;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.search-container .refresh-btn:hover {
  background: #064f69;
}

.send-btn {
  width: 30%;
  max-width: 100px;
  height: 44px;
  background: #ffad3b;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.send-btn:hover {
  background: #9b671f;
}

.preview-btn {
  width: 45px;
  height: 44px;
  background: #607d8b;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 20pt;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-btn:hover {
  background: #354852;
}

.table-responsive {
  position: relative;
  top: 30px;
  width: 96%;
  margin: 0 auto;
  border-collapse: collapse;
  font-family: "Courier New", Courier, monospace;
}

.table-responsive thead {
  height: 45px;
  text-align: center;
  background: #373737;
}

.table-responsive th {
  color: #fff;
}

.table-responsive thead,
.table-responsive tr,
.table-responsive th {
  border: 1px solid #dddddd;
}

.table-responsive tr {
  color: #545454;
  height: 60px;
}

.table-responsive tbody,
.table-responsive tbody tr,
.table-responsive tbody td {
  padding: 5px 0;
  text-align: center;
  font-size: 10pt;
  color: var(--dark);
}

.table-action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* Spinner */
.dot-spinner {
  --uib-size: 1.5rem;
  --uib-speed: 0.9s;
  --uib-color: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Pagination */
.page-wrapper {
  position: relative;
  width: 94%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 0 auto;
  margin-top: 50px;
}

.page-wrapper .page {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10pt;
  cursor: pointer;
  color: var(--dark);
  border: 1px solid #cacaca;
}

.page-wrapper .page:hover {
  background: var(--themecolor);
}

.page-unactive {
  background: -moz-linear-gradient(#ffffff, #e3e3e3);
  background: -webkit-linear-gradient(#ffffff, #e3e3e3);
  background: -o-linear-gradient(#ffffff, #e3e3e3);
  background: linear-gradient(#ffffff, #e3e3e3);
}

.page-active {
  background: #02aab0;
  background: -webkit-linear-gradient(to bottom, #00cdac, #02aab0);
  background: linear-gradient(to bottom, #00cdac, #02aab0);
  border: 1px solid #cacaca;
}

.page-prev {
  width: 80px;
  height: 31px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #dedede;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff, -1px -1px 0 #a1a1a1;
  border: 1px solid #cacaca;
  background: -moz-linear-gradient(#ffffff, #e3e3e3);
  background: -webkit-linear-gradient(#ffffff, #e3e3e3);
  background: -o-linear-gradient(#ffffff, #e3e3e3);
  background: linear-gradient(#ffffff, #e3e3e3);
  font-size: 10pt;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.page-next {
  width: 80px;
  height: 31px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #dedede;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff, -1px -1px 0 #a1a1a1;
  border: 1px solid #cacaca;
  background: -moz-linear-gradient(#ffffff, #e3e3e3);
  background: -webkit-linear-gradient(#ffffff, #e3e3e3);
  background: -o-linear-gradient(#ffffff, #e3e3e3);
  background: linear-gradient(#ffffff, #e3e3e3);
  font-size: 10pt;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
