<template>
  <div class="container" style="overflow: hidden;">
    <div class="search-container">
      <div class="left-panel">
        <input
          type="text"
          class="search-input"
          v-model="searchItem"
          @keyup.enter="searching"
        />
        <button class="search-btn" @click="searching">Search</button>
      </div>

      <div
        style="
          display: flex;
          flex-direction: row;
          width: 80svw;
          max-width: 700px;
        "
      >
        <div class="date-group">
          <label for="">From Date</label>
          <VueDatePicker
            v-model="fromdate"
            :enable-time-picker="false"
            :format="'dd/MM/yyyy'"
            auto-apply
          >
          </VueDatePicker>
        </div>

        <div class="date-group">
          <label for="">To Date</label>
          <VueDatePicker
            v-model="todate"
            :enable-time-picker="false"
            :format="'dd/MM/yyyy'"
            :min-date="new Date(fromdate)"
            auto-apply
          >
          </VueDatePicker>
        </div>
      </div>

      <button class="refresh-btn" @click="getCompareTransaction">
        <i class="ri-search-eye-line" style="font-size: 20pt"></i>
      </button>

      <button class="submit-btn" @click="submitCompare">
        <span v-if="!isSubmiting">Submit</span>
        <svg v-if="isSubmiting" viewBox="25 25 50 50">
          <circle r="20" cy="50" cx="50"></circle>
        </svg>
      </button>
    </div>

    <loader v-if="isLoading"></loader>

    <table class="table-responsive" aria-describedby="Payment Table">
      <thead>
        <th style="width: 5%">Kode Gudang</th>
        <th style="width: 25%">Nama Customer</th>
        <th style="width: 10%">Order ID</th>
        <th style="width: 25%">Merp Trans ID</th>
        <th style="width: 25%">Capture Trans ID</th>
        <th style="width: 5%">
          All <input type="checkbox" v-model="allselected" @click="selectAll" />
        </th>
      </thead>

      <tbody v-if="!isLoading">
        <tr v-if="payment.length <= 0">
          <td colspan="6">Tidak Ada Data</td>
        </tr>

        <tr
          v-for="data in payments"
          :key="data.orderid"
          :class="{
            'not-match':
            (data.trans_id !== data.transid &&
                data.transid !== '' &&
                data.transdate !== null) ||
            (data.trans_date !== data.transdate &&
                data.transid !== '' &&
                data.transdate !== null),
          }"
        >
          <td>{{ data.kdgudang }}</td>
          <td>{{ data.custname }}</td>
          <td>{{ data.orderid }}</td>
          <td>{{ data.trans_id }}</td>
          <td>{{ data.transid }}</td>
          <td>
            <input
              type="checkbox"
              v-if="
                (data.trans_id !== data.transid &&
                  data.transid !== '' &&
                  data.transdate !== null) ||
                (data.trans_date !== data.transdate &&
                  data.transid !== '' &&
                  data.transdate !== null)
              "
              :id="'check' + String(data.orderid)"
              style="margin-top: 5px"
              :value="data.orderid"
              @change="addToCart"
              class="input-box"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <div style="width: 100%;height: 20px;"></div>
  </div>

  <div style="width: 100%; height: 100px"></div>

  <notification 
      v-if="showNotification"
      :message="message"
      :success="succes">
  </notification>

  <alert-confirm 
      v-if="showAlert"
      :title="title" 
      :message="alertMessage" 
      :methods="methods" 
      :url="url" 
      :header="sheaders"
      :data="item"
      @onClosed="onClose"
      @onResolve="deleteItem">
  </alert-confirm>
</template>

<script>
import Loader from "@/components/ContentLoader.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import AlertConfirm from '@/components/AlertConfirmation.vue';
import Notification from "@/components/Notification.vue";
import axios from "axios";

export default {
  name: "CompareView",
  components: {
    Loader,
    VueDatePicker,
    AlertConfirm,
    Notification,
  },
  data() {
    return {
      fromdate: null,
      todate: null,
      payment: [],
      pagelength: 0,
      payments: [],
      totalPage: [],
      perpage: 10,
      selectedPage: 0,
      allselected: false,
      selectedPayment: [],
      isLoading: false,
      isSubmiting: false,
      searchItem: null,
      trx: [],
      showAlert: false,
      title: null,
      alertMessage: null,
      methods: null,
      url: null,
      sheaders: null,
      item: null,
    };
  },
  created() {
    this.fromdate = new Date();
  },
  methods: {
    async getCompareTransaction() {
      try {
        if (this.isLoading) {
          return;
        }

        this.isLoading = true;
        this.payment = null;
        this.payments = [];
        this.pagelength = 0;
        this.totalPage = [];
        this.selectedPage = 0;

        const fromdate = new Date(this.fromdate);
        const fmonth =
          fromdate.getMonth() + 1 <= 9
            ? "0" + String(fromdate.getMonth() + 1)
            : String(fromdate.getMonth() + 1);
        const fdate =
          fromdate.getDate() <= 9
            ? "0" + String(fromdate.getDate())
            : String(fromdate.getDate());

        const todate = new Date(this.todate);
        const tmonth =
          todate.getMonth() + 1 <= 9
            ? "0" + String(todate.getMonth() + 1)
            : String(todate.getMonth() + 1);
        const tdate =
          todate.getDate() <= 9
            ? "0" + String(todate.getDate())
            : String(todate.getDate());

        const body = {
          fromdate: `${fdate}-${fmonth}-${String(fromdate.getFullYear())}`,
          todate: `${tdate}-${tmonth}-${String(fromdate.getFullYear())}`,
        };

        const { data } = await axios.post(
          "/payment/compare-transaction",
          body,
          {
            headers: {
              Authorization: this.$store.getters.GET_AUTH_TOKEN,
            },
          }
        );

        this.payment = data.data;
        this.payments = this.payment

        console.log(this.payment)
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async submitCompare() {
      try {
        if (this.isSubmiting) {
          return;
        }

        if (this.selectedPayment.length <= 0) {
          return;
        }

        this.isSubmiting = true;
        this.selectedPayment.map((data) => {
            const newPayDate = new Date(data.transdate)
            const month = (newPayDate.getMonth() + 1) <= 9 ? '0' + String(newPayDate.getMonth() + 1) : String(newPayDate.getMonth() + 1)
            const date = newPayDate.getDate() <= 9 ? '0' + String(newPayDate.getDate()) : String(newPayDate.getDate())
            data.transdate = `${date}-${month}-${String(newPayDate.getFullYear())}`
        })
        
        const data = {
            payment: this.selectedPayment
        }

        this.item = data
        this.url = "/payment/update-compare"
        this.methods = 'post'
        this.title = 'Confirmation'
        this.alertMessage = 'Are you sure want to commit Transaction ?'
        this.sheaders = {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
        }
 
        this.showAlert = true;
        this.isSubmiting = false;
      } catch (error) {
        console.log(error);
      }
    },
    addToCart(e) {
      this.allselected = false;

      const id = e.srcElement._value;
      if (e.srcElement.checked == true) {
        const find = this.payment.filter((obj) => {
          return obj.orderid === id;
        });

        if (find && find[0].transid !== "" && find[0].transdate !== null) {
          this.selectedPayment.push(find[0]);
        }
      } else if (e.srcElement.checked == false) {
        const find = this.payment.filter((obj) => {
          return obj.id === id;
        });

        this.selectedPayment.splice(this.selectedPayment.indexOf(find[0]), 1);
      }
    },
    selectAll() {
      this.selectedPayment = [];
      if (!this.allselected) {
        this.allselected = true;
        this.payment.forEach((data) => {
          if (data.trans_id !== data.transid || data.trans_date !== data.transdate) {
            if (data.transid !== "" && data.transdate !== null) {
               document.getElementById("check" + String(data.orderid)).checked = true;
               this.selectedPayment.push(data);
            }
          }
        });
      } else {
        this.allselected = false;
        this.payment.forEach((data) => {
          if (
            data.trans_id !== data.transid ||
            data.trans_date !== data.transdate
          ) {
            if (data.transid !== "" && data.transdate !== null) {
                document.getElementById("check" + String(data.orderid)).checked = false;
            }
          }
        });
      }
    },
    searching() {
      this.trx = [];
      this.payments = [];
      this.totalPage = [];
      const searchTerm = "*" + this.searchItem + "*";
      const wildcardRegex = new RegExp(
        "^" + searchTerm.replace(/\*/g, ".*") + "$",
        "i"
      );
      this.payments = this.payment.filter((obj) =>
        Object.values(obj).some(
          (value) => typeof value === "string" && wildcardRegex.test(value)
        )
      );
    },
    onClose(value){
      this.showAlert = value
      this.deleteMessage = null
      this.title = false
      this.url = null
      this.methods = null
    },
    deleteItem(data){
      this.showAlert = false
      this.message = data.message;
      this.succes = true;
      this.showNotification = true;

      setTimeout(() => {
        this.message = null;
        this.succes = false;
        this.showNotification = false;
        window.location.reload()
      }, 1300)
    },
  },
};
</script>
