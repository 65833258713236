<template>
  <div class="body-container">
    <div class="payment-dialog">
      <div
        class="rsv-card"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          overflow: hidden;
        "
      >
        <div
          class="icon icon--order-success svg"
          style="width: 200px; height: 180px"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="72px"
            height="72px"
            style="transform: scale(2); margin-top: 50px"
          >
            <g fill="#f3f3f3" stroke="#8EC343" stroke-width="2">
              <circle
                cx="36"
                cy="36"
                r="35"
                style="stroke-dasharray: 240px, 240px; stroke-dashoffset: 480px"
              ></circle>
              <path
                d="M17.417,37.778l9.93,9.909l25.444-25.393"
                style="stroke-dasharray: 50px, 50px; stroke-dashoffset: 0px"
              ></path>
            </g>
          </svg>
        </div>

        <span class="rsv-title">Payment Success!</span>
        <p>Your payment success, Please check your mail for the details!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import url('https://fonts.cdnfonts.com/css/pathway-extreme');

.rsv-card {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 15pt;  
  font-family: 'Pathway Extreme', sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
}

@-webkit-keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-ms-keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}

@-ms-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}

@keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}

.inlinesvg .svg svg {
  display: inline;
}

.icon--order-success svg path {
  -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
  animation: checkmark 0.25s ease-in-out 0.7s backwards;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.icon--order-success svg circle {
  -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
  animation: checkmark-circle 0.6s ease-in-out backwards;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
</style>
