<template>
  <navbar
    v-if="
      this.$route.name === 'dashboard' ||
      this.$route.name === 'compare-transaction'
    "
  ></navbar>
  <router-view />
</template>

<script>
import Navbar from './components/Navbar.vue';
export default{
  name: 'App',
  components: {
    Navbar
  }
}
</script>
