import { createRouter, createWebHistory } from 'vue-router'
import PaymentView from '../views/PaymentView.vue'
import LoginView from '../views/LoginView.vue'
import DashboardView from '../views/DashboardView.vue'
import PaymentSuccess from '../views/PaymentSuccess.vue'
import PaymentFailed from '../views/PaymentFailed.vue'
import LandingView from '../views/LandingView.vue'
import CompareView from '../views/CompareView.vue'
import PaymentVA from  '../views/PaymentVA.vue'
// import PaymentXendit from '../views/PaymentXendit.vue'
import store from '../store'

const routes = [
  { path: '/', name: 'landing', component: LandingView},
  { path: '/payment/:orderId', name: 'payment', component: PaymentView },
  // { path: '/payment-xendit', name: 'payment-xendit', component: PaymentXendit},
  { path: '/payment-va/:id', name: 'payment-va', component: PaymentVA},
  { path: '/compare-transaction', name: 'compare-transaction', component: CompareView, meta: {login: true}},
  { path: '/login', name: 'login', component: LoginView, meta: {guest: true} },
  { path: '/dashboard', name: 'dashboard', component: DashboardView, meta: {login: true} },
  { path: '/sucess', name: 'success', component: PaymentSuccess },
  { path: '/failed', name: 'failed', component: PaymentFailed },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(document.getElementById("map")){
    document.getElementById("map").remove()
  }

  window.scrollTo(0, 0);
  if(to.matched.some(record => record.meta.login)){
    if (!store.getters.GET_AUTH_TOKEN){
      next({
        name: 'login'
      })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.guest)){
    if (store.getters.GET_AUTH_TOKEN) {
      next({
        name: 'dashboard'
      })
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router
