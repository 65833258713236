<template>
  <nav class="navbar">
      <img src="/logo.png" style="width: 8svw;height: 5svh;object-fit:cover;margin-left: 20px;">

      <div class="nav-menu">
        <nav class="nav-link"
          :class="{'link-active': this.$route.name === 'dashboard'}"
          @click="this.$router.push('/dashboard')">
          <span>Dashboard</span>
        </nav>

        <nav class="nav-link" 
          :class="{'link-active': this.$route.name === 'compare-transaction'}"
          @click="this.$router.push('/compare-transaction')">
          <span>Compare</span>
        </nav>
      </div>

      <div class="user-panel" @click="showDropdown">
        <img id="profileimg" class="profile-img">
        <span class="profile-name">{{ name }}</span>
      </div>

      <div class="dropdown" v-if="isDrop">
        <i style="font-size: 18pt;margin-left: 10px;color: #a5082a;font-weight: bold;" 
        class="ri-logout-circle-r-line"></i>
        <div class="dropdown-link" @click="logout">Logout</div>
      </div>
  </nav>

  <div v-if="isLogout" class="loader-container">
    <div class="loading-spinner"></div>
  </div>
</template>

<script>
export default {
  name: 'NavbarVue',
  data(){
    return {
      isDrop: false,
      userImage: null,
      isLogout: false,
      name: null,
    }
  },
  created(){
    this.userImage = this.$store.getters.GET_AUTH_INFO.photo
    this.name = this.$store.getters.GET_AUTH_INFO.name
  },
  mounted(){
    this.getUserImage(this.userImage);
  },
  methods: {
    showDropdown(){
      if(this.isDrop) this.isDrop = false;
      else this.isDrop = true;
    },
    logout(){
      this.isLogout = true;
      this.$store.dispatch("LOGOUT")
      .then(() => {
          this.isLogout = false;
          this.$router.push({ name: 'login'});
      }).catch(() => {
        this.isLogout = false;
          this.$router.push({ name: 'login'});
      });
    },
    async getUserImage(filename){
      await fetch(this.uri + '/user/image/' + filename, {
        method: 'GET',
        headers: { 
            'Authorization' : this.$store.getters.GET_AUTH_TOKEN
        }
      }).then((res) => {
            return res.blob()
      }).then((blob) => {
            let url = URL.createObjectURL(blob)
            document.getElementById('profileimg').src = url
      })
    }
  }
}
</script>

<style>
.navbar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100svw;
  height: 7svh;
  background: #fff;
  z-index: 1000;
  box-shadow: 0px 17px 11px -7px rgba(0,0,0,0.1);
  display: flex;
  justify-content: start;
  align-items: center;
}

.user-panel{
  position: absolute;
  right: 0;
  width: 200px;
  height: 100%;
  margin-right: 2svw;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-panel:hover{
  background: #1baadd;
  color: #fff;
}

.profile-img{
  height: 5svh;
  width: auto;
  object-fit:cover;
  border-radius: 50%;
  border: 2px solid #08a586;
  cursor: pointer;
  margin-left: 10px;
}

.profile-name{
  font-size: 14pt;
  margin-left: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #656565;
}

.dropdown{
  position: absolute;
  top: 7.3svh;
  right: 1.9svw;
  width: 200px;
  height: auto;
  background: #fff;
  font-size: 12pt;
  box-shadow: 5px 5px 4px 0px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
}

.dropdown:hover{
  background: #1baadd;
  color: #fff;
}

.dropdown-link{
  width: 100%;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #656565;
}

/* Loader */
.loader-container{
  position: fixed;
  top:0;
  left:0;
  width: 100svw;
  height: 100svh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffad;
}

.loading-spinner {
  width: 100px;
  height: 100px;
  display: grid;
}

.loading-spinner::before,
.loading-spinner::after {
  content: "";
  grid-area: 1/1;
  background: var(--c) 50%  0, 
        var(--c) 50%  100%, 
        var(--c) 100% 50%, 
        var(--c) 0    50%;
  background-size: 13.4px 13.4px;
  background-repeat: no-repeat;
  animation: spinner-3hs4a3 1s infinite;
}

.loading-spinner::before {
  --c: radial-gradient(farthest-side,rgb(138, 12, 40) 92%,#0000);
  margin: 4.5px;
  background-size: 9px 9px;
  animation-timing-function: linear;
}

.loading-spinner::after {
  --c: radial-gradient(farthest-side,rgb(138, 12, 40) 92%,#0000);
}

@keyframes spinner-3hs4a3 {
  100% {
    transform: rotate(.5turn);
  }
}

.nav-menu{
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.nav-link{
  width: 120px;
  height: 100%;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Outfit', sans-serif;
  cursor: pointer;
}

.nav-link:hover{
  background: #1baadd;
  color: #fff;
}

.link-active{
  background: #1baadd;
  color: #fff;
}
</style>